<template>
  <div>
    <!-- Start Content  -->
    <!-- Modal Change Password -->
    <b-modal
      v-model="modalPass.isShow"
      :title="$t('SETTING.CHANGE_PASS')"
      @ok="submitModalPass"
    >
      <b-form @submit.stop.prevent="changePass" ref="form">
        <b-form-group :label="$t('SETTING.NEW_PASSWORD') + ':'">
          <b-form-input
            type="password"
            :placeholder="$t('SETTING.ENTER_NEW_PASSWORD')"
            v-model="modalPass.newPass"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('SETTING.CONFIRM_PASSWORD') + ':'">
          <b-form-input
            type="password"
            :placeholder="$t('SETTING.ENTER_NEW_PASSWORD')"
            v-model="modalPass.confirmNewPass"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="ok()"
          >
            {{ $t("MODAL.OK") }}
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            class="float-right mr-2"
            @click="cancel()"
          >
            {{ $t("MODAL.CANCEL") }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Modal API Access -->
    <b-modal
      v-model="modalGas.isShow"
      :title="$t('SETTING.UPDATE_GAS_FEE')"
      @ok="submitModalGas"
    >
      <b-form @submit.stop.prevent="updateGasFee" ref="form">
        <b-form-group :label="`BEP-20 ${$t('WITHDRAWAL.WITHDRAWAL_FEE')}:`">
          <b-form-input
            type="number"
            placeholder="Enter BEP-20 Gas"
            v-model="modalGas.bep_20_wd_fee"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="`ERC-20 ${$t('WITHDRAWAL.WITHDRAWAL_FEE')}:`">
          <b-form-input
            type="number"
            placeholder="Enter ERC-20 Gas"
            v-model="modalGas.erc_20_wd_fee"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('SETTING.INCLUDE_NETWORK_FEE') + ':'">
          <span class="switch switch-icon">
            <label>
              <input
                type="checkbox"
                name="select"
                v-model="modalGas.is_include_network_fee"
              />
              <span></span>
            </label>
          </span>
          <label class="description">
            <label class="red-star">* </label>
            {{ $t("SETTING.DESCRIPTION_NETWORK_FEE") }}
          </label>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="ok()"
          >
            {{ $t("MODAL.OK") }}
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            class="float-right mr-2"
            @click="cancel()"
          >
            {{ $t("MODAL.CANCEL") }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Modal 2fa -->
    <b-modal
      id="modal-scoped"
      v-model="modal2Fa.isShow"
      no-close-on-backdrop
      @ok="verify2fa"
      modal-class="twofa-modal"
      @cancel-disabled="true"
    >
      <template #modal-header="{ close }">
        <h4>
          {{
            !!currentUser.g_auth
              ? $t("TABLE.DISABLE_2FA")
              : $t("TABLE.ENABLE_2FA")
          }}
        </h4>
      </template>
      <b-form ref="form">
        <b-form-group label="">
          <ol class="twoFactorAuth__list">
            <li class="twoFactorAuth__step" v-if="!currentUser.g_auth">
              <span class="twoFactorAuth__title">{{
                $t("AUTHENTICATOR.PLEASE_SCAN")
              }}</span>
              <img :src="modal2Fa.qr" alt="qr code" class="twoFactorAuth__qr" />
              <div class="twoFactorAuth__code">{{ modal2Fa.code }}</div>
              <span class="twoFactorAuth__note">{{
                $t("AUTHENTICATOR.UNABLE_SCAN")
              }}</span>
            </li>
            <li class="twoFactorAuth__step" v-if="!currentUser.g_auth">
              <span class="twoFactorAuth__title">{{
                $t("AUTHENTICATOR.SAVE_KEY")
              }}</span>
              <div class="twoFactorAuth__code">{{ modal2Fa.code }}</div>
              <span class="twoFactorAuth__note">{{
                $t("AUTHENTICATOR.RECOVER")
              }}</span>
            </li>
            <li class="twoFactorAuth__step">
              <span class="twoFactorAuth__title">{{
                $t("AUTHENTICATOR.VERIFY_ACCOUNT")
              }}</span>
              <b-input
                size="sm"
                v-model="modal2Fa.verification_code"
                type="number"
                :state="verificationCodeState"
                class="twoFactorAuth__verification"
                aria-invalid="false"
              ></b-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{ $t("AUTHENTICATOR.VALIDATION_VERIFICATION_CODE") }}
              </b-form-invalid-feedback>
              <span class="twoFactorAuth__note">{{
                $t("AUTHENTICATOR.ENTER_DIGIT")
              }}</span>
            </li>
          </ol>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="ok()"
          >
            {{ $t("MODAL.OK") }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Start List  -->
    <!-- Table Setting -->
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 py-3 mt-3 page-title">
        <h3 class="card-title align-items-center">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t("MENU.SETTING") }}
          </span>
        </h3>
      </div>
      <div class="card-body pb-3">
        <div class="d-flex justify-content-start pb-5">
          <div>
            <b-button
              size="sm"
              class="mr-3"
              variant="primary"
              @click="showModalPass()"
            >
              {{ $t("SETTING.CHANGE_PASS") }}
            </b-button>
          </div>
        </div>
        <b-table
          class="bg-white mt-5"
          striped
          hover
          :fields="fields"
          :items="items"
          v-if="currentTier.id !== 'ADMIN'"
        >
          <!-- Set No Data when none of item -->
          <template #bottom-row="items" v-if="items.length === 0">
            <b-td :colspan="fields.length" class="text-center">{{
              $t("TABLE.NO_DATA")
            }}</b-td>
          </template>

          <!-- A virtual column -->
          <template #cell(created_at)="data">
            {{ data.item.created_at | moment("DD-MM-YYYY HH:mm:ss") }}
          </template>
        </b-table>
      </div>
    </div>

    <!-- Table Gas Fee -->
    <div
      class="card card-custom card-stretch gutter-b"
      v-if="currentTier.id === 'ADMIN'"
    >
      <div class="card-header border-0 py-3 mt-3 page-title">
        <h3 class="card-title align-items-center">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("SETTING.GAS_FEE")
          }}</span>
        </h3>
        <div>
          <b-button
            v-if="getAccess.includes('setting--UPDATE')"
            size="sm"
            class="mr-3"
            variant="primary"
            @click="showModalGas(itemsGas[0])"
            >{{ $t("SETTING.UPDATE_GAS_FEE") }}</b-button
          >
        </div>
      </div>
      <div class="card-body pb-3">
        <!-- <b-table class="bg-white" striped hover :items="items"> -->
        <b-table
          class="bg-white"
          striped
          hover
          :fields="fieldsGas"
          :items="itemsGas"
        >
          <!-- Set No Data when none of item -->
          <template #bottom-row="itemsGas" v-if="itemsGas.length === 0">
            <b-td :colspan="fieldsGas.length" class="text-center">{{
              $t("TABLE.NO_DATA")
            }}</b-td>
          </template>

          <template #cell(is_include_network_fee)="data">
            {{ data.item.is_include_network_fee ? "&#10003;" : "&#x274C;" }}
          </template>
        </b-table>
      </div>
    </div>
    <!-- End List  -->
    <!-- End Content  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

import { UPDATE_2FA } from "@/core/services/store/auth.module";
import {
  GET_API,
  UPDATE_PASS,
  TOGGLE_2FA
} from "@/core/services/store/setting.module";
import {
  GET_GAS_FEE,
  UPDATE_GAS_FEE
} from "@/core/services/store/gas-fee.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import speakeasy from "speakeasy";
import QRCode from "qrcode";
import globalMixin from "@/core/plugins/mixins.js";

export default {
  mixins: [globalMixin],
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("TABLE.CLIENT_ID"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "username",
          label: this.$t("TABLE.USERNAME"),
          tdClass: "align-middle",
          thClass: "text-center"
        },
        {
          key: "name",
          label: this.$t("TABLE.NAME"),
          tdClass: "align-middle",
          thClass: "text-center"
        },
        {
          key: "api_key",
          label: this.$t("TABLE.API_KEY"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "secret_key",
          label: this.$t("TABLE.SECRET_KEY"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "fee",
          label: this.$t("TABLE.COMMISSION") + "(%)",
          tdClass: "align-middle text-center",
          thClass: "text-center"
        }
      ],
      items: [],
      modalPass: {
        isShow: false,
        newPass: "",
        confirmNewPass: ""
      },
      fieldsGas: [
        {
          key: "bep_20_wd_fee",
          label: `BEP-20 ${this.$t("WITHDRAWAL.WITHDRAWAL_FEE")}`,
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "erc_20_wd_fee",
          label: `ERC-20 ${this.$t("WITHDRAWAL.WITHDRAWAL_FEE")}`,
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "is_include_network_fee",
          label: this.$t("SETTING.INCLUDE_NETWORK_FEE"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        }
      ],
      itemsGas: [],
      modalGas: {
        isShow: false,
        bep_20_wd_fee: 0,
        erc_20_wd_fee: 0,
        is_include_network_fee: false
      },
      modal2Fa: {
        isShow: false,
        qr: "",
        code: "",
        verification_code: ""
      }
    };
  },
  mounted() {
    this.getApi();
    if (this.currentTier.id === "ADMIN") {
      this.getGasFee();
    }
    if (!this.googleAuth) {
      this.showVerfiyModal();
    }
  },
  methods: {
    async generate2faQRCode() {
      const secret = speakeasy.generateSecret({
        name: `Crimsonpay Admin (${this.currentUser.username})`,
        length: 10
      });

      // Get the data URL of the authenticator URL
      this.modal2Fa.qr = await QRCode.toDataURL(secret.otpauth_url);
      this.modal2Fa.code = secret.hex;
    },
    showVerfiyModal() {
      this.modal2Fa.isShow = true;
      this.modal2Fa.verification_code = "";

      if (this.currentUser.g_auth) {
        this.modal2Fa.code = this.currentUser.g_auth;
      } else {
        this.generate2faQRCode();
      }
    },
    verify2fa(e) {
      e.preventDefault();

      // Use verify() to check the token against the secret
      var verified = speakeasy.totp.verify({
        secret: this.modal2Fa.code,
        encoding: "hex",
        token: this.modal2Fa.verification_code
      });

      if (verified) {
        const secret = this.currentUser.g_auth ? null : this.modal2Fa.code;
        this.$store
          .dispatch(TOGGLE_2FA, { secret })
          .then((e) => {
            this.$store.dispatch(UPDATE_2FA, secret);
            this.modal2Fa.isShow = false;
            this.getApi();
            Swal.fire({
              text:
                e.status.toUpperCase() == "ENABLED"
                  ? this.$t("AUTHENTICATOR.VALIDATOR_ENABLED")
                  : this.$t("AUTHENTICATOR.VALIDATOR_DISABLED"),
              type: "success"
            });
          })
          .catch((err) => {
            Swal.fire({
              text: this.translateErrorFromCode(err),
              type: "error",
              confirmButtonText: this.$t("MODAL.OK")
            });
          })
          .then(() => {
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          });
      } else {
        Swal.fire({
          text: this.$t("AUTHENTICATOR.INVALID_CODE"),
          type: "error",
          confirmButtonText: this.$t("MODAL.OK")
        });
      }
    },
    getApi() {
      this.items = [];
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(GET_API, { username: this.currentUser.username })
        .then((e) => {
          this.items.push(e);
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    showModalPass() {
      this.modalPass.newPass = "";
      this.modalPass.confirmNewPass = "";
      this.modalPass.isShow = true;
    },
    submitModalPass(modalEvent) {
      // Prevent modal from closing
      modalEvent.preventDefault();
      // Trigger submit handler
      this.changePass();
    },
    changePass() {
      const $self = this;
      if (this.modalPass.newPass === this.modalPass.confirmNewPass) {
        this.$store
          .dispatch(UPDATE_PASS, {
            username: this.currentUser.username,
            password: this.modalPass.confirmNewPass
          })
          .then(() => {
            Swal.fire({
              text: this.$t("MODAL.UPDATE_SUCESSFULL"),
              type: "success",
              confirmButtonText: this.$t("MODAL.OK")
            }).then(() => {
              $self.modalPass.isShow = false;
            });
          })
          .catch((err) => {
            Swal.fire({
              text: this.translateErrorFromCode(err),
              type: "error",
              confirmButtonText: this.$t("MODAL.OK")
            });
          })
          .then(() => {
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          });
      } else {
        Swal.fire({
          text: this.$t("AUTHENTICATOR.VALIDATION_CONFIRM_PASSWORD"),
          type: "error",
          confirmButtonText: this.$t("MODAL.OK")
        });
      }
    },
    getGasFee() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(GET_GAS_FEE, {})
        .then((e) => {
          this.itemsGas = [];
          this.itemsGas.push(e);
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    showModalGas(dt) {
      this.modalGas.erc_20_gas = dt.erc_20_gas;
      this.modalGas.bep_20_gas = dt.bep_20_gas;
      this.modalGas.bep_20_wd_fee = dt.bep_20_wd_fee;
      this.modalGas.erc_20_wd_fee = dt.erc_20_wd_fee;
      this.modalGas.is_include_network_fee = dt.is_include_network_fee;
      this.modalGas.isShow = true;
    },
    submitModalGas(modalEvent) {
      // Prevent modal from closing
      modalEvent.preventDefault();
      // Trigger submit handler
      this.updateGasFee();
    },
    updateGasFee() {
      const $self = this;
      const reqBody = {};

      if (this.modalGas.bep_20_wd_fee) {
        reqBody.bep_20_wd_fee = this.modalGas.bep_20_wd_fee;
      }
      if (this.modalGas.erc_20_wd_fee) {
        reqBody.erc_20_wd_fee = this.modalGas.erc_20_wd_fee;
      }

      reqBody.is_include_network_fee = this.modalGas.is_include_network_fee;

      this.$store
        .dispatch(UPDATE_GAS_FEE, reqBody)
        .then(() => {
          $self.modalGas.isShow = false;
          Swal.fire({
            text: this.$t("MODAL.UPDATE_SUCESSFULL"),
            type: "success",
            confirmButtonText: this.$t("MODAL.OK")
          }).then(() => {
            $self.getGasFee();
          });
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        });
    }
  },
  computed: {
    ...mapGetters(["currentTier", "currentUser", "getAccess", "googleAuth"]),
    verificationCodeState() {
      const code = this.modal2Fa.verification_code;

      if (code.length == 0) return null;
      return code.length == 6 ? true : false;
    }
  }
};
</script>
<style scoped lang="scss">
.page-title {
  min-height: auto !important;
}

::v-deep .twofa-modal + .modal-backdrop { // override vue bootstrap modal backdrop
  opacity: 1 !important;
}

.twoFactorAuth {
  &__list {
    font-size: 15px;
    list-style-position: inside;
  }

  &__step {
    margin: 25px 0 15px;
    border-bottom: 1px solid #90929c;
    padding-bottom: 20px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 20px;
    display: inline-block;
  }

  &__qr {
    display: block;
    margin: 0 auto;
  }

  &__code {
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }

  &__verification {
    margin-bottom: 5px;
  }

  &__note {
    font-size: 12px;
  }
}

/*Customize the description */
.description {
  display: block;
  padding-left: 6px;
  margin: 3px 0 12px 0;
  font-size: 10px;
  color: grey;
}

/*Customize warning label color */
.red-star {
  color: red;
  font-size: 10px;
}
</style>
